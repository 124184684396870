import { defineMessages } from 'react-intl';

export const connectingMessages = defineMessages({
  title: {
    id: 'ios.modals.pause-insulin.title',
    defaultMessage: 'Connecting',
  },
  text: {
    id: 'ios.modals.pause-insulin.text',
    defaultMessage: 'Please wait...',
  },
});
